<template>
  <div class="about">
    <!-- <br /><br /><br /><br /> -->
    <v-row class="text-center" style="display:flex; justify-content: flex-end;">
      <v-btn dark style="margin-right: 50px" @click="openParams" v-if="!dialog2"
        >Params</v-btn
      >
    </v-row>
    <br /><br />
    <!-- <v-col class="mb-4" offset="1" cols="12" sm="10" md="10">
      
      </v-col> -->
    <v-row class="text-center">
      <v-card max-width="94%" style="margin-left: 50px" dark v-if="!dialog2">
        <v-data-table
          style="margin-left: 50px;margin-right: 50px"
          dark
          :headers="headersTotals"
          :items="totals"
          hide-default-footer
        ></v-data-table>
      </v-card>
    </v-row>
    <br /><br /><br />
    <v-row class="text-center">
      <v-card max-width="94%" style="margin-left: 50px" dark v-if="!dialog2">
        <v-card-title>
          <v-autocomplete
            dark
            v-model="blockChosen"
            :items="blocks"
            item-text="block"
            outlined
            dense
            chips
            small-chips
            label="Blocks"
            clearable
            multiple
            @change="filterByBlock"
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-card-title>
        <v-data-table
          style="margin-left: 50px;margin-right: 50px"
          dark
          :headers="headers"
          dense
          :items="desserts"
          :search="search"
        >
          <template v-slot:item.unitName="{ item }">
            <v-chip
              class="chip"
              dark
              :id="item.unitName"
              small
              @click="redirectToUnitInfo"
              color="amber"
              >{{ item.unitName }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <v-snackbar v-model="snackbar" bottom top shaped color="blue">
      {{ snackbarMessage }}
      <v-btn color="pink" text timeout="10000" @click="snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card dark style="border: solid 3px gold" v-if="dialog">
        <v-card-title>
          <span class="text-h5">Params</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="params[0].fundsRequired"
                  label="Funds Required"
                  required
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="params[0].raisingFees"
                  label="Raising Fees"
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="params[0].structuringFees"
                  label="Structuring Fees"
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="params[0].commission"
                  label="Commission"
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="params[0].transferFees"
                  label="TransferFees"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="params[0].bondRegistration"
                  label="bondRegistration"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="params[0].trustReleaseFees"
                  label="trustReleaseFees"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="white" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="updateParams">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialog2"> -->
    <v-card
      dark
      style="border: solid 3px gold; position:absolute; left: 10;top:50;"
      v-if="dialog2"
    >
      <v-card-text>
        <!-- <v-row class="text-center"> -->
        <!-- <v-card max-width="94%" style="margin-left: 50px" dark> -->

        <v-data-table
          style="margin-left: 50px;margin-right: 50px"
          dark
          :headers="investorTotals"
          dense
          :items="selectedUnits"
          hide-default-footer
          :item-class="itemRowColor"
        ></v-data-table>
        <br /><br /><br />
        <v-data-table
          style="margin-left: 50px;margin-right: 50px"
          dark
          :headers="headersTotals"
          :items="totalsUnit"
          hide-default-footer
        ></v-data-table>
        <!-- </v-card> -->
        <!-- </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="dialog2 = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import axios from "axios";
let url = process.env.VUE_APP_BASEURL;

export default {
  name: "InvestorSummary",
  components: {
    // PlanType1
  },
  metaInfo: {
    title: "About us",
    titleTemplate: "CPC - %s",
    meta: [
      {
        name: `description`,
        content: `About CPC here.`
      }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      unfilteredData: [],
      blocks: [],
      blockChosen: null,
      dialog: false,
      dialog2: false,
      snackbar: false,
      snackbarMessage: "",
      search: "",

      headers: [
        {
          text: "Unit",
          align: "start",
          filterable: true,
          value: "unitName"
        },
        // { text: "Block", value: "block", width: 90 },

        { text: "Sale Price", value: "contractPriceStr", width: 150 },
        { text: "VAT", value: "VATStr", width: 150 },
        { text: "Gross", value: "netAfterVATStr", width: 150 },
        { text: "Commission", value: "commisionStr", width: 110 },
        { text: "Transfee Fees", value: "transferFeesStr", width: 110 },
        { text: "Bond Registration", value: "bondRegistrationStr", width: 110 },
        {
          text: "Trust Release Fees",
          value: "trustReleaseFeesStr",
          width: 110
        },
        { text: "UnForseen", value: "unforseenStr", width: 110 },
        { text: "Transfer Income", value: "transferIncomeStr", width: 150 },
        { text: "Due to investors", value: "investorRepaymentStr", width: 150 },
        { text: "Surplus / Deficit", value: "surplusDeficitStr", width: 150 }
        // { text: "Iron (%)", value: "iron" }
      ],
      headersTotals: [
        {
          text: "Sale Price",
          value: "contractPriceStr",
          width: 140,
          align: "center"
        },
        { text: "VAT", value: "VATStr", width: 140, align: "center" },
        { text: "Gross", value: "netAfterVATStr", width: 140, align: "center" },
        {
          text: "Commission",
          value: "commisionStr",
          width: 140,
          align: "center"
        },
        {
          text: "Transfer Fees",
          value: "transferFeesStr",
          width: 140,
          align: "center"
        },
        {
          text: "Bond Registration",
          value: "bondRegistrationStr",
          width: 140,
          align: "center"
        },
        {
          text: "Trust Release Fees",
          value: "trustReleaseFeesStr",
          width: 140,
          align: "center"
        },
        {
          text: "UnForseen",
          value: "unforseenStr",
          width: 140,
          align: "center"
        },
        {
          text: "Transfer Income",
          value: "transferIncomeStr",
          width: 140,
          align: "center"
        },
        {
          text: "Due to investors",
          value: "investorRepaymentStr",
          width: 140,
          align: "center"
        },
        {
          text: "Surplus / Deficit",
          value: "surplusDeficitStr",
          width: 140,
          align: "center"
        }
      ],
      investorTotals: [
        { text: "Unit", value: "unitName", width: 90 },

        { text: "Code", value: "investor_code", width: 90 },
        { text: "Investor", value: "investor", width: 180 },
        { text: "Status", value: "status", width: 90 },
        {
          text: "Amount Invested",
          value: "attorney_inv_amountStr",
          width: 110,
          align: "end"
        },
        { text: "Interest", value: "interestDueStr", width: 110, align: "end" },

        {
          text: "Repayment Amount",
          value: "investorRepaymentStr",
          width: 110,
          align: "end"
        },
        {
          text: "Transfer Date",
          value: "repayment_date",
          width: 110,
          align: "end"
        }

        // {
        //   text: "Trust Release Fees",
        //   value: "trustReleaseFeesStr",
        //   width: 150,
        // },
        // { text: "UnForseen", value: "unforseenStr", width: 150 },
        // { text: "Transfer Income", value: "transferIncomeStr", width: 150 },
        // { text: "Due to investors", value: "investorRepaymentStr", width: 150 },
        // { text: "Surplus / Deficit", value: "surplusDeficitStr", width: 150 },
      ],

      investorData: [],
      desserts: [],
      totals: [],
      totalsUnit: [],
      params: [],
      selectedUnit: "",
      selectedUnits: [],
      unitInfo: []
    };
  },
  beforeMount() {
    this.getInitialData();
  },
  methods: {
    filterByBlock() {
      console.log("Block Chosen", this.blockChosen);
      console.log(this.blockChosen.length);

      let blocky = [];
      this.blockChosen.forEach(el => {
        blocky.push(el);
      });
      if (blocky.length < 6) {
        for (let i = blocky.length + 1; i < 7; i++) {
          blocky.push("");
        }
      }
      console.log(blocky);
      console.log(blocky.length);

      // this.search = this.blockChosen;
      if (this.blockChosen.length !== 0) {
        let newTotals = this.unfilteredData.filter(el => {
          return (
            el.block === blocky[0] ||
            el.block === blocky[1] ||
            el.block === blocky[2] ||
            el.block === blocky[3] ||
            el.block === blocky[4] ||
            el.block === blocky[5]
          );
          // return el.block === this.blockChosen;
        });
        this.desserts = newTotals;
        let totals = [];
        console.log(newTotals);
        let contractPriceStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.contractPrice;
          }, 0)
        );
        let VATStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.VAT;
          }, 0)
        );
        let netAfterVATStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.netAfterVAT;
          }, 0)
        );
        let transferFeesStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.transferFees;
          }, 0)
        );
        let bondRegistrationStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.bondRegistration;
          }, 0)
        );
        let trustReleaseFeesStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.trustReleaseFees;
          }, 0)
        );
        let unforseenStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.unforseen;
          }, 0)
        );
        let transferIncomeStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.transferIncome;
          }, 0)
        );
        let commisionStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.commision;
          }, 0)
        );
        let surplusDeficitStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.surplusDeficit;
          }, 0)
        );
        let investorRepaymentStr = this.convertToString(
          newTotals.reduce((prev, curr) => {
            return prev + curr.investorRepayment;
          }, 0)
        );
        let insert = {
          contractPriceStr: contractPriceStr,
          VATStr: VATStr,
          netAfterVATStr: netAfterVATStr,
          transferFeesStr: transferFeesStr,
          bondRegistrationStr: bondRegistrationStr,
          trustReleaseFeesStr: trustReleaseFeesStr,
          unforseenStr: unforseenStr,
          transferIncomeStr: transferIncomeStr,
          commisionStr: commisionStr,
          surplusDeficitStr: surplusDeficitStr,
          investorRepaymentStr: investorRepaymentStr
        };
        totals.push(insert);
        this.totals = totals;
        console.log(this.totals);
      } else {
        console.log(this.blockChosen);
        this.getInitialData();
        // this.desserts = this.unfilteredData
        // let totals = [];

        // let contractPriceStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.contractPrice;
        //   }, 0)
        // );
        // let VATStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.VAT;
        //   }, 0)
        // );
        // let netAfterVATStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.netAfterVAT;
        //   }, 0)
        // );
        // let transferFeesStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.transferFees;
        //   }, 0)
        // );
        // let bondRegistrationStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.bondRegistration;
        //   }, 0)
        // );
        // let trustReleaseFeesStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.trustReleaseFees;
        //   }, 0)
        // );
        // let unforseenStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.unforseen;
        //   }, 0)
        // );
        // let transferIncomeStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.transferIncome;
        //   }, 0)
        // );
        // let commisionStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.commision;
        //   }, 0)
        // );
        // let surplusDeficitStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.surplusDeficit;
        //   }, 0)
        // );
        // let investorRepaymentStr = this.convertToString(
        //   this.desserts.reduce((prev, curr) => {
        //     return prev + curr.investorRepayment;
        //   }, 0)
        // );
        // let insert = {
        //   contractPriceStr: contractPriceStr,
        //   VATStr: VATStr,
        //   netAfterVATStr: netAfterVATStr,
        //   transferFeesStr: transferFeesStr,
        //   bondRegistrationStr: bondRegistrationStr,
        //   trustReleaseFeesStr: trustReleaseFeesStr,
        //   unforseenStr: unforseenStr,
        //   transferIncomeStr: transferIncomeStr,
        //   commisionStr: commisionStr,
        //   surplusDeficitStr: surplusDeficitStr,
        //   investorRepaymentStr: investorRepaymentStr
        // };
        // totals.push(insert);
        // this.totals = totals;
        // this.unfilteredData = this.desserts
      }
    },
    itemRowColor(item) {
      //CHANGES ROW COLOR WHEN TASK BEHIND SCGEDULE
      if (item.unitName == "TOTAL") {
        return "amber--text";
        // class="white--text"
      }
    },
    redirectToUnitInfo(e) {
      this.selectedUnits = [];
      console.log("ID", e.currentTarget.id);
      let selectedRow = this.desserts.filter(el => {
        return el.unitName === e.currentTarget.id;
      });
      console.log(this.desserts[0]);
      console.log("selectedRow", selectedRow);
      this.selectedUnit = e.currentTarget.id;
      this.selectedUnits = this.investorData.filter(el => {
        return this.selectedUnit === el.unitName;
      });
      this.selectedUnits.forEach(el => {
        el.attorney_inv_amountStr = this.convertToString(
          el.attorney_inv_amount
        );
        el.interestDueStr = this.convertToString(el.interestDue);
        el.investorRepaymentStr = this.convertToString(el.investorRepayment);
        if (el.quinteDate === null) {
          el.status = "pledged";
        } else {
          el.status = "invested";
        }
        el.repayment_date = dayjs(el.repayment_date).format("YYYY-MM-DD");
        console.log(el.repayment_date);
      });
      let attorney_inv_amountStr = this.convertToString(
        this.selectedUnits.reduce((prev, curr) => {
          return prev + curr.attorney_inv_amount;
        }, 0)
      );
      let interestDueStr = this.convertToString(
        this.selectedUnits.reduce((prev, curr) => {
          return prev + curr.interestDue;
        }, 0)
      );
      let investorRepaymentStr = this.convertToString(
        this.selectedUnits.reduce((prev, curr) => {
          return prev + curr.investorRepayment;
        }, 0)
      );
      let insert = {
        attorney_inv_amountStr: attorney_inv_amountStr,
        interestDueStr: interestDueStr,
        investorRepaymentStr: investorRepaymentStr,
        unitName: "TOTAL"
      };
      this.selectedUnits.push(insert);

      let totalsUnit = selectedRow;
      //       let contractPriceStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.contractPrice;
      //         }, 0)
      //       );
      //       let VATStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.VAT;
      //         }, 0)
      //       );
      //       let netAfterVATStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.netAfterVAT;
      //         }, 0)
      //       );
      //       let transferFeesStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.transferFees;
      //         }, 0)
      //       );
      //       let bondRegistrationStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.bondRegistration;
      //         }, 0)
      //       );
      //       let trustReleaseFeesStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.trustReleaseFees;
      //         }, 0)
      //       );
      //       let unforseenStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.unforseen;
      //         }, 0)
      //       );
      //       let transferIncomeStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.transferIncome;
      //         }, 0)
      //       );
      //       let commisionStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.commision;
      //         }, 0)
      //       );
      //       let surplusDeficitStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.surplusDeficit;
      //         }, 0)
      //       );
      //       let investorRepaymentStr = this.convertToString(
      //         this.selectedUnits.reduce((prev, curr) => {
      //           return prev + curr.investorRepayment;
      //         }, 0)
      //       );
      //       let insert = {
      //         contractPriceStr: contractPriceStr,
      //         VATStr: VATStr,
      //         netAfterVATStr: netAfterVATStr,
      //         transferFeesStr: transferFeesStr,
      //         bondRegistrationStr: bondRegistrationStr,
      //         trustReleaseFeesStr: trustReleaseFeesStr,
      //         unforseenStr: unforseenStr,
      //         transferIncomeStr: transferIncomeStr,
      //         commisionStr: commisionStr,
      //         surplusDeficitStr: surplusDeficitStr,
      //         investorRepaymentStr: investorRepaymentStr
      //       };
      //       totalsUnit.push(insert);
      this.totalsUnit = totalsUnit;
      console.log(this.selectedUnits);
      this.dialog2 = true;
    },
    async updateParams() {
      console.log(this.params);
      let data = this.params[0];
      await axios({
        method: "post",
        url: `${url}/updateInvestorParamsWB`,
        data: data
      })
        .then(
          response => {
            console.log(response.data);
            this.dialog = false;
            this.getInitialData();
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    openParams() {
      this.dialog = true;
    },

    async getInitialData() {
      let data = {
        id: this.$store.state.development.id
      };
      await axios({
        method: "post",
        url: `${url}/getInvestmentSummaryDataWB`,
        data: data
      })
        .then(
          response => {
            // console.log(response.data[0]);
            console.time("Loop");
            this.params = response.data[3];

            let data = [];
            response.data[2].forEach((el, index, arr) => {
              if (index !== 0 && el.repayment_date === null) {
                el.repayment_date = arr[index - 1].repayment_date;
              }
              let units = response.data[0].filter(el2 => {
                return el2.id === el.unit;
              });
              console.log("units", units);
              el.unitName = units[0].unitName;
              el.blockName = units[0].subsectionName;
              if (el.fica_inv_date !== null) {
                el.startTrustInterestDate = dayjs(el.fica_inv_date).format(
                  "YYYY-MM-DD"
                );
              } else {
                el.startTrustInterestDate = null;
              }
              if (el.quinteDate !== null) {
                el.startContractInterestDate = dayjs(el.quinteDate).format(
                  "YYYY-MM-DD"
                );
              } else {
                el.startContractInterestDate = null;
              }
              if (el.startTrustInterestDate !== null) {
                if (el.startContractInterestDate !== null) {
                  el.trustInterestDays = dayjs(
                    el.startContractInterestDate
                  ).diff(dayjs(el.startTrustInterestDate), "d");
                } else {
                  el.trustInterestDays = dayjs(el.repayment_date).diff(
                    dayjs(el.startTrustInterestDate),
                    "d"
                  );
                }
              } else {
                el.trustInterestDays = 0;
                el.contractInterestDays = 0;
              }
              if (el.startContractInterestDate !== null) {
                el.contractInterestDays =
                  dayjs(el.repayment_date).diff(
                    dayjs(el.startContractInterestDate),
                    "d"
                  ) + 1;
              } else {
                el.contractInterestDays = 0;
              }
              el.interestDue =
                ((el.attorney_inv_amount * el.trust_account_interest) / 365) *
                  el.trustInterestDays +
                ((el.attorney_inv_amount * el.interest_rate) / 365) *
                  el.contractInterestDays;
              el.investorRepayment = el.attorney_inv_amount + el.interestDue;
              if (isNaN(el.investorRepayment)) {
                console.log(el);
              }
            });
            this.investorData = response.data[2];
            this.unitInfo = response.data[1];

            response.data[1].forEach(el => {
              let insert = {};
              let filteredSalesData = response.data[0].filter(el2 => {
                return el2.id === el.unit && el2.unitName !== "Pledged";
              });
              // console.log(filtered)
              if (filteredSalesData.length) {
                let investorDataFiltered = response.data[2].filter(el3 => {
                  return el3.unit === el.unit;
                });

                if (investorDataFiltered.length) {
                  insert.investorRepayment = investorDataFiltered.reduce(
                    (prev, curr) => {
                      return prev + curr.investorRepayment;
                    },
                    0
                  );
                } else {
                  insert.investorRepayment =
                    (el.contract_price * response.data[3][0].fundsRequired) /
                    100;
                }
                insert.unit = el.unit;
                insert.blockName = el.blockName;
                insert.unitName = filteredSalesData[0].unitName;
                insert.contractPrice = el.contract_price;
                insert.VAT = (insert.contractPrice / 1.15) * 0.15;
                insert.netAfterVAT = insert.contractPrice - insert.VAT;
                insert.transferFees = response.data[3][0].transferFees;
                insert.bondRegistration = response.data[3][0].bondRegistration;
                insert.trustReleaseFees = response.data[3][0].trustReleaseFees;
                insert.unforseen = insert.contractPrice * 0.005;
                insert.commision =
                  parseFloat(insert.netAfterVAT) *
                  (parseFloat(response.data[3][0].commission) / 100);
                insert.transferIncome =
                  insert.contractPrice -
                  insert.transferFees -
                  insert.bondRegistration -
                  insert.trustReleaseFees -
                  insert.unforseen -
                  insert.commision;
                insert.surplusDeficit =
                  insert.transferIncome - insert.investorRepayment;
                insert.contractPriceStr = this.convertToString(
                  insert.contractPrice
                );
                insert.VATStr = this.convertToString(insert.VAT);
                insert.netAfterVATStr = this.convertToString(
                  insert.netAfterVAT
                );
                insert.transferFeesStr = this.convertToString(
                  insert.transferFees
                );
                insert.bondRegistrationStr = this.convertToString(
                  insert.bondRegistration
                );
                insert.trustReleaseFeesStr = this.convertToString(
                  insert.trustReleaseFees
                );
                insert.unforseenStr = this.convertToString(insert.unforseen);
                insert.transferIncomeStr = this.convertToString(
                  insert.transferIncome
                );
                insert.commisionStr = this.convertToString(insert.commision);
                insert.surplusDeficitStr = this.convertToString(
                  insert.surplusDeficit
                );
                insert.investorRepaymentStr = this.convertToString(
                  insert.investorRepayment
                );
                data.push(insert);
              }
            });

            console.timeEnd("Loop");
            // console.log(data);
            console.log(response.data[0]);
            this.desserts = data;
            this.desserts.forEach(el => {
              let unitInfo = response.data[0].filter(el2 => {
                return el.unit === el2.id;
              });
              el.block = unitInfo[0].subsectionName;
              console.log(unitInfo);
            });

            console.log(this.desserts);

            // this.desserts.sort((a,b) => {
            //   return b.unitName - a.unitName
            // })
            // this.desserts.sort((a, b) => b.lunitName.localeCompare(a.unitName));
            this.desserts.sort(function(a, b) {
              if (a.unitName < b.unitName) {
                return -1;
              }
              if (a.unitName > b.unitName) {
                return 1;
              }
              return 0;
            });
            let totals = [];
            let contractPriceStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.contractPrice;
              }, 0)
            );
            let VATStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.VAT;
              }, 0)
            );
            let netAfterVATStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.netAfterVAT;
              }, 0)
            );
            let transferFeesStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.transferFees;
              }, 0)
            );
            let bondRegistrationStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.bondRegistration;
              }, 0)
            );
            let trustReleaseFeesStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.trustReleaseFees;
              }, 0)
            );
            let unforseenStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.unforseen;
              }, 0)
            );
            let transferIncomeStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.transferIncome;
              }, 0)
            );
            let commisionStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.commision;
              }, 0)
            );
            let surplusDeficitStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.surplusDeficit;
              }, 0)
            );
            let investorRepaymentStr = this.convertToString(
              this.desserts.reduce((prev, curr) => {
                return prev + curr.investorRepayment;
              }, 0)
            );
            let insert = {
              contractPriceStr: contractPriceStr,
              VATStr: VATStr,
              netAfterVATStr: netAfterVATStr,
              transferFeesStr: transferFeesStr,
              bondRegistrationStr: bondRegistrationStr,
              trustReleaseFeesStr: trustReleaseFeesStr,
              unforseenStr: unforseenStr,
              transferIncomeStr: transferIncomeStr,
              commisionStr: commisionStr,
              surplusDeficitStr: surplusDeficitStr,
              investorRepaymentStr: investorRepaymentStr
            };
            totals.push(insert);
            this.totals = totals;
            this.unfilteredData = [];
            let blocks = [];
            this.desserts.forEach(el => {
              this.unfilteredData.push(el);
              blocks.push(el.block);
            });
            console.log(blocks);
            this.blocks = Array.from(new Set(blocks));
            console.log(this.blocks);
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped>
.chip {
  cursor: pointer;
  color: black;
}
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
